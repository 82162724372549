@tailwind base;
@tailwind components;
@tailwind utilities;


.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 300px);
  /* padding-top: 30px; */
  margin-top: 10px;
  
}

.react-player iframe {
  /* width: 100%;
  height: 100%; */
  width: auto;
  height: auto;
}

.customOverlay {
  background: rgba(3, 3, 0, 0.4);
}
.customModal {
  background: #c79d2b;
  width: "auto";
  height: "auto";
}





/* * {
  margin: 0;
  padding: 0;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
} */

/* .main {
  width: 100%;
  height: 100vh;
  background-color: rgb(221, 189, 44);
} */


/* 
.image-gallery {
  width: 100%;
  height: auto;
} 

.image-gallery-slide img {
  width: 100%;
  height: 900px;
  max-height: 83vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}

 */
